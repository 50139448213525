import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { signInWithCustomToken } from 'firebase/auth';

import { useFirebase } from "../config/FirebaseContext";

import Loader from "./Loader";
import { PageNotFound } from "./Common";
import { AdminAPI } from "../actions/AdminAPI";

export default function AuthByToken() {
  const query = new URLSearchParams(useLocation().search);
  const customToken = query.get("customToken");
  const userId = query.get("userId");

  const { auth } = useFirebase();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth) {
      console.warn('Auth is not initialized');
      return;
    }

    const authenticateUser = async () => {
      if (customToken) {
        try {
          await signInWithCustomToken(auth, customToken);
          window.location.replace("/");
        } catch (error) {
          console.error("Error signing in with custom token:", error);
          setIsLoading(false);
        }
      } else if (userId) {
        try {
          const response = await AdminAPI.getCustomToken(userId);

          if (response.customToken) {
            await signInWithCustomToken(auth, response.customToken);
            window.location.replace("/");
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error fetching custom token:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    authenticateUser();
  }, [customToken, userId]);

  return (
    <div className="auth-by-token">
      {isLoading ? <Loader /> : <PageNotFound />}
    </div>
  );
}
