import React, { useState, useEffect } from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { signInWithGoogle, signInWithEmail } from "../../../../firebase";

import Loader from "../../../Loader";
import Button from "../../../Button";
import { supportLink } from "../../../../helpers";
import { getRedirectUrl, addActualParamsToUrl } from "../../../../utils";

import SignInLayout from "../../../Layouts/SignInLayout";
import { IconEmail, IconFacebook, IconGoogle } from '../../../../icons';
import { SignInButton, ButtonProps } from './SignInButton';
import LoginWithEmail from './LoginWithEmail';
import { useFirebase } from "../../../../config/FirebaseContext";
const loadingStatuses = {
  idle: "idle",
  loading: "loading",
  error: "error",
  success: "success",
};

export default function SignIn(props: { withEmail?: boolean; }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { auth } = useFirebase();
  const [loadingStatus, setLoadingStatus] = useState(isSignInWithEmailLink(auth!, window.location.href) ? loadingStatuses.loading : loadingStatuses.idle);

  function onGoogleSignInClick() {
    signInWithGoogle()
      .then(() => setLoadingStatus(loadingStatuses.loading))
      .catch(() => setLoadingStatus(loadingStatuses.error));
  }

  function onEmailSignInClick() {
    history.push(addActualParamsToUrl("/login/with-email"));
  }

  if (props.withEmail) {
    return (
      <SignInLayout title={t("Sign in with Email")} backUrl={addActualParamsToUrl("/login")}>
        {/* <SignInWithEmailOld /> */}
        <LoginWithEmail />
      </SignInLayout>
    );
  }

  return (
    <SignInLayout title={t("Sign in")}>
      <div className="mt-8">
        {loadingStatus === loadingStatuses.idle && (
          <div className="sign-in-buttons flex flex-col">
            <SignInGoogleButton onClick={onGoogleSignInClick} />
            <SignInEmailButton onClick={onEmailSignInClick} />
          </div>
        )}

        {loadingStatus === loadingStatuses.loading && (
          <Loader />
        )}

        {loadingStatus === loadingStatuses.error && (
          <div className={'flex flex-col items-center'}>
            <div className="mb-4 text-md">
              {t("Oops, something went wrong.")}
              <br />
              {t("Please try again or")}{" "}
              <a className="border-b-2" href={supportLink} target="_blank" rel="noopener noreferrer" type="text">
                {t("contact us")}
              </a>
              .
            </div>
            <Button large name={t("Try again")} className="inline-block" handler={() => setLoadingStatus(loadingStatuses.idle)} />
          </div>
        )}
      </div>
    </SignInLayout>
  );
}


function SignInWithEmailOld() {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const auth = getAuth();
  const [loadingStatus, setLoadingStatus] = useState(isSignInWithEmailLink(auth, window.location.href) ? loadingStatuses.loading : loadingStatuses.idle);

  useEffect(() => {
    const auth = getAuth();
    if (!isSignInWithEmailLink(auth, window.location.href)) return;

    const email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      setLoadingStatus(loadingStatuses.error);
      return;
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem("emailForSignIn");
      })
      .catch((error) => {
        console.error(error);
        setLoadingStatus(loadingStatuses.error);
      });
  }, []);

  return (<>
    {loadingStatus === loadingStatuses.idle && (
      <Formik
        initialValues={{ email: "" }}
        validate={({ email }) => {
          if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return { email: t("Email is incorrect") };
          return {};
        }}
        onSubmit={({ email }) => {
          signInWithEmail(email, getRedirectUrl() || '')
            .then(() => {
              window.localStorage.setItem("emailForSignIn", email);
              setUserEmail(email);
              setLoadingStatus(loadingStatuses.success);
            })
            .catch((error) => {
              console.error(error);
              setLoadingStatus(loadingStatuses.error);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-xs mx-auto mb-6">{t("Enter the email address below, and we’ll send a magic link to your inbox.")}</div>
            <input
              name="email"
              disabled={isSubmitting}
              className="mb-2 text-2xl leading-tight px-1 py-2 text-center"
              style={{ width: 320 }}
              placeholder={t("Enter your email here")}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && <div className="mb-2 text-base font-medium text-red-600">{errors.email}</div>}
            <SignInButton disabled={(errors.email && touched.email) || isSubmitting}>
              {isSubmitting ? t("Please, wait...") : t("Continue")}
            </SignInButton>
          </form>
        )}
      </Formik>
    )}

    {loadingStatus === loadingStatuses.success && (
      <div className="max-w-xs text-center mx-auto">
        {t("Click on the link we sent to ")}<span className="font-medium">{userEmail}</span>{t("to sign in.")}
        <span className="block mt-4">{t("If you did not receive an email, check your Spam or Junk folder.")}</span>
      </div>
    )}

    {loadingStatus === loadingStatuses.error && (
      <div>
        <div className="text-base font-medium text-red-600 mb-4">
          {t("Oops, something went wrong.")}
          <br />
          {t("Please try again or contact us.")}
        </div>
        <SignInButton onClick={() => setLoadingStatus(loadingStatuses.idle)}>
          {t("Try again")}
        </SignInButton>
      </div>
    )}

    {loadingStatus === loadingStatuses.loading && (
      <div>
        <Loader />
      </div>
    )}
  </>)
}


function SignInGoogleButton(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <SignInButton {...props}>
      <IconGoogle width={24} height={24} className="mr-2" />
      {t("Sign in with Google")}
    </SignInButton>
  );
}

function SignInEmailButton(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <SignInButton {...props} className='sign-in-email-button' dataTestId='sign-in-email-button'>
      <IconEmail width={32} height={32} className="mr-2" />
      {t("Sign in with Email")}
    </SignInButton>
  );
}

function SignInFacebookButton(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <SignInButton {...props}>
      <IconFacebook width={24} height={24} className="mr-2" />
      {t("Sign in with Facebook")}
    </SignInButton>
  );
}

