import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReportAPI, ReportConfigData } from '../../../actions/ReportAPI';

// типизация запроса на обновление конфига
export type UpdateConfigRequest = { config: any, blockType: string } | null;


export function useReportConfig(options: { testId: string, blockId?: string, sharingToken?: string }) {
	const queryClient = useQueryClient();

	const reportConfig = useQuery<ReportConfigData>({
		queryKey: ['config/reports', options.testId],
		queryFn: async () => {
			const response = await ReportAPI.getReportConfig(options.testId, options.sharingToken);
			return response;
		},
		staleTime: Infinity,
		enabled: !!options.testId
	});

	const updateBlockConfig = useMutation<{ result: string }, Error, UpdateConfigRequest>({
		mutationFn: async (update: UpdateConfigRequest) => {
			const response = await ReportAPI.updateReportBlockConfig(options.testId, options.blockId, update);
			return response;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['config/reports', options.testId] });
		},
	});

	return {
		reportConfig,
		updateBlockConfig
	}
}