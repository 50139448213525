import { useState } from "react";
import { ReportAPI } from "../../../actions/ReportAPI";

interface UseSummarizeTextRepliesArgs {
	testId: string;
	blockId: string;
	question: string;
	responses: string[];
};

export const useSummarize = (options: UseSummarizeTextRepliesArgs) => {
	const [summary, setSummary] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const getSummary = async () => {
		setIsLoading(true);
		setSummary(null);
		setError(null);

		try {
			const response = await ReportAPI.getReportAiSummary(options.testId, options.blockId,
				{
					question: options.question,
					responses: options.responses
				});

			setSummary(response.content);
		} catch (error) {
			console.error(error);
			setError("UNABLE_TO_SUMMARIZE_TEXT_REPLIES");
		}

		setIsLoading(false);
	};

	return { summary, getSummary, error, isLoading };
};
