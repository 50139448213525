import { authorizedClient } from "../http/Client";


export class TeamsAPI {
  static baseURL = '/api/teams';

  public static async getTeam(projectId?: string): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/getTeam`, { projectId });
    return response.data;
  }

  public static async updateTeam(projectId: string, data: any): Promise<any> {
    const response = await authorizedClient.put(`${this.baseURL}/${projectId}`, data);
    return response.data;
  }

  public static async updateTeamSettings(data: { projectId: string, field: string, value: string }): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/updateTeam`, data);
    return response.data;
  }

  public static async removeTeamMember(projectId: string, memberId: string): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/removeTeamMember`, { projectId, memberId });
    return response.data;
  }

  public static async updateTeamMemberRole(projectId: string, memberId: string, role: string): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/updateTeamMemberRole`, { projectId, memberId, role });
    return response.data;
  }
}
