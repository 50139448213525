import { AxiosResponse } from "axios";

import { SignWithEmailDTO } from "../components/UserAccount/Pages/Login/LoginWithEmail";
import { apiClient } from "../http/Client";


export class AuthAPI {
  static baseURL = '/api/v1/auth';

  public static async login(data: SignWithEmailDTO): Promise<AxiosResponse<any>> {
    const response = await apiClient.post(`${this.baseURL}/login`, data);
    return response;
  }

  public static async verify(data: { email: string; code: string; }): Promise<AxiosResponse<any>> {
    const response = await apiClient.post(`${this.baseURL}/verify`, data);
    return response;
  }

}