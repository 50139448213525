import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppConfigDTO } from '../Common/schema/AppConfig/AppConfigDTO';
import { ConfigAPI } from '../actions/ConfigAPI';

interface IConfigContextValue {
	config: AppConfigDTO | null;
	isLoading: boolean;
	error: Error | null;
}

const ConfigContext = createContext<IConfigContextValue | null>(null);

export function ConfigProvider(props: PropsWithChildren) {
	const { data: config, isLoading, error } = useQuery({
		queryKey: ['appConfig'],
		queryFn: () => ConfigAPI.getConfig().then(config => {
			window.pwconfig = config;
			return config;
		}),
		staleTime: Infinity, // Config won't become stale
		retry: 3, // Retry 3 times on failure
		refetchOnWindowFocus: false, // Don't refetch when window regains focus
	});

	const contextValue: IConfigContextValue = {
		config: config || null,
		isLoading,
		error: error as Error | null,
	};

	return (
		<ConfigContext.Provider value={contextValue}>
			{props.children}
		</ConfigContext.Provider>
	);
}

export function useConfig() {
	const context = useContext(ConfigContext);
	if (context === null) {
		throw new Error('useConfig must be used within a ConfigProvider');
	}
	return context;
} 