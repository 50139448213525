import { useEffect, useRef, useState } from "react";

import { MIME_TYPES } from "../../../../../Common/constants";
import { baseURL } from "../../../../../http/Client";
import { AnswersAPI } from "../../../../../actions/AnswersAPI";


export default function useVideoContent(recordingFolder: string, recordingId: string, isScreenRecord: boolean, isCameraRecord: boolean, isMicrophoneRecord: boolean, sharingToken?: string) {
  const videoFaceRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoFaceError, setVideoFaceError] = useState(false);
  const [videoScreenError, setVideoScreenError] = useState(false);
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);
  const [videoSource, setVideoSource] = useState<{ url: string; type: string } | null>(null);

  const withRespondentRecord = isCameraRecord || isMicrophoneRecord;
  const [loadedVideoCount, setLoadedVideoCount] = useState(withRespondentRecord ? 2 : 1);

  const videoCanvasUrl = `${baseURL}/api/v1/answers/${recordingFolder}/${recordingId}/video?sharingToken=${sharingToken}`;
  const videoScreenUrl = `${baseURL}/api/v1/answers/${recordingFolder}/${recordingId}/screen/video?sharingToken=${sharingToken}`;

  const videoUrl = isScreenRecord ? videoScreenUrl : videoCanvasUrl;
  const videoFaceUrl = `${baseURL}/api/v1/answers/${recordingFolder}/${recordingId}/respondent/video?sharingToken=${sharingToken}`;

  function handleVideoError(type: "screen" | "face") {
    if (type === "screen") {
      setVideoScreenError(true);
    } else {
      setVideoFaceError(true);
    }
    handleReduceLoadedVideoCount();
  }

  function handleReduceLoadedVideoCount() {
    setLoadedVideoCount(prev => prev - 1);
  }

  async function detectVideoType(url: string, type: string) {
    try {
      const response = await AnswersAPI.getAnswerVideoType(url);
      const contentType = response || type;

      setVideoSource({
        url: url,
        type: contentType,
      });
    } catch (error) {
      console.error('Error detecting video type:', error);
      setVideoSource({ url: url, type: type });
    }
  };

  useEffect(() => {
    if (loadedVideoCount <= 0) {
      setIsPlayerLoading(false);
    }
  }, [loadedVideoCount]);

  useEffect(() => {
    if (videoUrl) detectVideoType(videoUrl, MIME_TYPES.VIDEO_WEBM);
  }, [videoUrl]);

  return {
    videoUrl,
    videoFaceUrl,
    videoFaceRef,
    videoRef,
    videoFaceError,
    videoScreenError,
    withRespondentRecord,
    isPlayerLoading,
    setIsPlayerLoading,
    handleVideoError,
    loadedVideoCount,
    handleReduceLoadedVideoCount,
    videoSource,
  }

}