import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from "./locales/en/translations.json";
import ruTranslations from "./locales/ru/translations.json";
import { AppConfigDTO } from '../Common/schema/AppConfig/AppConfigDTO';

export const defaultNS = 'translation';

export enum Language {
	en = "en",
	ru = "ru",
}

type Resources = {
	en: { translation: typeof enTranslations };
	ru?: { translation: typeof ruTranslations };
};

export const resources: Resources = {
	en: {
		translation: enTranslations,
	},
};

export function initializeI18n(config: AppConfigDTO) {
	if (config.languages.includes('ru')) {
		resources.ru = {
			translation: ruTranslations,
		};
	}

	i18n.languages = config.languages; //Object.values(Language);

	i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			detection: {
				order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
			},
			fallbackLng: Language.en,
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
			},
			resources,
		})
		.then((_) => {
			i18n.changeLanguage();
			// console.log('i18n initialized', _ as any, i18n.resolvedLanguage);
		});
}

export function getCurrentLanguage() {
	return i18n.resolvedLanguage || "en";
}

export default i18n;