import { AxiosError } from 'axios';

import { apiClient } from '../http/Client';
import { INativePrototypeData } from '../models/Test';
import { IFigmaPrototype } from "../Common/schema/prototype";

export class PrototypesAPI {
    static async fetchPrototype(protoId: string, options?: { signal?: AbortSignal }): Promise<IFigmaPrototype> {
		try {
			const response = await apiClient.get(`/api/prototypes/${protoId}`, { signal: options?.signal });
			return response.data;
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 400) {
					throw new Error('bad_request', { cause: error });
				}
			}
			throw new Error('http_error', { cause: error });
		}
	}

	static async loadFromUrl(url: string) {
		try {
			const response = await apiClient.get<INativePrototypeData>(url, { responseType: 'json' });
			return response.data;
		} catch (error) {
			throw new Error('http_error', { cause: error });
		}
	}
}