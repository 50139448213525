import { authorizedClient } from "../http/Client";

export interface IUserDataRequest {
  userId: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  detectedLanguage: string;
}

export class UserAPI {
  static async getUser(userId: string) {
    const response = await authorizedClient.get(`/api/v1/users/${userId}`);
    return await response.data;
  }

  static async getUserData(data: IUserDataRequest) {
    const response = await authorizedClient.post(`/api/user/getUserData`, data);
    return await response.data;
  }
}