import { FigmaAPI } from "./FigmaAPI";

export class UserActions {
	public static async setFigmaAuth(userId: string, code: string | null, state: string | null) {
		try {
			const response = await FigmaAPI.setFigmaAuth(code, state);

			return {
				type: "SET_USER_DATA",
				payload: {
					userId: userId,
					data: response,
				}
			}
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}
}
