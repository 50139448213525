import React, { useCallback, useContext } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from "react-i18next";

import "../tailwind.generated.css";

import AppContext, { DefaultAppState } from "../AppContext";

import Button from "./Button";
import { useFirebase } from "../config/FirebaseContext";

const SignOut = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const { auth } = useFirebase();

  const onSignOutButtonClick = useCallback(() => {
    if (!auth) {
      throw new Error('Auth is not initialized');
    }
    auth.signOut().then(() => {
      queryClient.clear();
      dispatch({ type: 'RESET_STATE', payload: DefaultAppState });
    });
  }, [auth, queryClient, dispatch]);

  return (
    <Button
      handler={onSignOutButtonClick}
      name={t("Sign out")}
      type="secondary"
    />
  );
};

export default SignOut;
