import * as Sentry from '@sentry/react';

export interface ILoggerService {
  /**
    * tagType is used for classifying log messages in Alerts, 
    * which makes it easier to filter and analyze logs.
    */
  warning(message: string, tagType: string, extra?: Record<string, any>): void;
  error(message: string, tagType: string, extra?: Record<string, any>): void;
}


export class SentryLogger implements ILoggerService {
  public warning(message: string, tagType: string, extra?: Record<string, any>) {
    Sentry.captureMessage(message,
      {
        level: 'warning',
        tags: {
          type: tagType,
        },
        extra,
      }
    );
  }

  public error(message: string, tagType: string, extra?: Record<string, any>) {
    Sentry.captureMessage(message,
      {
        level: 'error',
        tags: {
          type: tagType,
        },
        extra,
      }
    );
  }
}

export class ConsoleLogger implements ILoggerService {
  public warning(message: string, tagType: string, extra?: Record<string, any>) {
    console.warn(message, extra);
  }

  public error(message: string, tagType: string, extra?: Record<string, any>) {
    console.error(message, extra);
  }
}
