import { getAuth, sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { PaymentMethodCode } from './models/Account/IAccount';

declare global {
  interface Window {
    pwconfig: {
      contact_email: string,
      languages: string[],
      teams_tariff_id: number,
      default_payment_method: PaymentMethodCode,
      firebase_config: any,
      help_center_domain: string,
      tag_manager_code: string,
      is_global_app: boolean,
      storage: 'google' | 'aws' | 'aws_with_fallback',
      urls: {
        license_agreement: string,
        landing_page: string,
      },
      panel: {
        batch_size: number,
        prolific: {
          batch_size: number,
          minimum_base_test_duration_mins: number,
        },
        crowd: {
          batch_size: number,
          minimum_base_test_duration_mins: number,
        }
      },
      sentry: {
        dsn: string,
        environment: string,
      }
    };
  }
}

// Initialize Firebase
// firebase.initializeApp(window.pwconfig.firebase_config);

export let FieldValue = firebase.firestore.FieldValue;
export let Timestamp = firebase.firestore.Timestamp;

export function getAuthToken() {
  const auth = getAuth();
  return new Promise((resolve) => {
    //@ts-ignore
    auth.currentUser.getIdToken(true).then(resolve);
  });
}

export function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  const auth = getAuth();
  return signInWithPopup(auth, provider);
}

export function signInWithEmail(email: string, redirectUrl: string) {
  let url = `${window.location.protocol}//${window.location.host}/login/with-email`;

  if (redirectUrl) {
    url = url + `?redirectUrl=${redirectUrl}`;
  }

  const auth = getAuth();
  return sendSignInLinkToEmail(auth, email, {
    url,
    handleCodeInApp: true,
  });
}
