import { ChatDTO } from '../Common/schema/Chats/ChatDTO';
import { CreateMessageTagDTO, DeleteMessageTagDTO, IMessageTag } from '../Common/schema/Chats/UpdateChatMessageDTO';
import { authorizedClient } from '../http/Client';

const basePath = '/api/v1/report';

export class ReportChatAPI {

	public static async getChats(testId: string, blockId: string) {
		const response = await authorizedClient.get<ChatDTO[]>(`${basePath}/${testId}/${blockId}/chats`);
		return response.data;
	}

	public static async getTags(testId: string, blockId: string) {
		const response = await authorizedClient.get<IMessageTag[]>(`${basePath}/${testId}/${blockId}/tags`);
		return response.data;
	}

	public static async tagMessage(testId: string, blockId: string, request: CreateMessageTagDTO) {
		const response = await authorizedClient.post<IMessageTag>(`${basePath}/${testId}/${blockId}/tags`, request);

		return response.data;
	}

	public static async unTagMessage(testId: string, blockId: string, tagId: number, request: DeleteMessageTagDTO) {
		const urlSearchParams = new URLSearchParams(request as any);
		const response = await authorizedClient.delete<IMessageTag>(`${basePath}/${testId}/${blockId}/tags/${tagId}?${urlSearchParams.toString()}`);

		return response.data;
	}
}
