import { CanceledError } from "axios";

export async function handleNetworkErrors<T>(requestFn: () => Promise<T>): Promise<T> {
  try {
    return await requestFn();
  } catch (error: any) {
    let errorCode = "UNKNOWN_ERROR"; 

    if (error?.response) {
      if (error.response.data?.error_code) {
        errorCode = error.response.data.error_code;
      } else if (error.response.status >= 500) {
        errorCode = "SERVER_ERROR";
      }
    } else if (error?.request) {
      errorCode = "NETWORK_ERROR";
    } else if (error?.message === "Network Error") {
      errorCode = "NETWORK_ERROR";
    }

    console.error(`Error occurred: ${errorCode}`, error);
    throw new Error(errorCode);
  }
}

export function isAbortError(error: Error): boolean {
  return error instanceof CanceledError && error.code === "ERR_CANCELED";
}
