import { useState, useEffect, useContext } from "react";
import { User } from 'firebase/auth';

import appContext from "./AppContext";
import { setNotification } from "./actions";
import { useFirebase } from "./config/FirebaseContext";

export function useAuth() {
  const { auth } = useFirebase();
  const [user, setUser] = useState<User | null | boolean>(null);

  useEffect(() => {
    if (!auth) return;
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  return user;
}

export type NotificationType = "success" | "info" | "error" | "warning" | "loading" | null;

export function useSendNotification() {
  const { dispatch } = useContext(appContext);

  const sendNotification = (type: NotificationType, text: string | null) => dispatch(setNotification(type, text));

  return sendNotification;
}

export function useFlag(flag: string) {
  const { state } = useContext(appContext);
  const isOn = state.user.flags?.[flag] === true;
  return isOn;
}
