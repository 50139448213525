import { authorizedClient } from "../http/Client";

export interface ISubscriptionOrderRequest {
  tariffId: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
  idempotencyKey: string;
}

export interface ICreditsOrderRequest {
  quantity: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
  idempotencyKey: string;
}

export interface IOrderResponse {
  result?: 'error';
  status: 'success' | 'confirmation_required',
  payment_url?: string
}

export class AccountAPI {

  static async fetchCurrentBalance(): Promise<{ balance: number }> {
    const response = await authorizedClient.get('/api/v1/account/balance');
    return response.data;
  }

  static async changeSubscription(data: { isRecurring: boolean }, signal?: AbortSignal): Promise<any> {
    const response = await authorizedClient.put(`/api/v1/account/subscription`, data, { signal });
    return response.data;
  }

  static async upgradeSubscription(data: ISubscriptionOrderRequest, signal?: AbortSignal): Promise<any> {
    const request = { productCode: 'subscription', ...data };
    const response = await authorizedClient.post(`/api/v1/orders`, request, { signal });
    return response.data;
  }

  static async buyCredits(data: ICreditsOrderRequest, signal?: AbortSignal): Promise<any> {
    const request = { productCode: 'credits', ...data };
    const response = await authorizedClient.post(`/api/v1/orders`, request, { signal });
    return response.data;
  }

  static async addPaymentMethod(data: { productCode: string, paymentMethodCode: string }, signal?: AbortSignal): Promise<any> {
    const response = await authorizedClient.post(`/api/v1/orders`, data, { signal });
    return response.data;
  }

  static async capturePayment(paymentId: number, signal?: AbortSignal): Promise<IOrderResponse> {
    const response = await authorizedClient.post(`/api/v1/orders/${paymentId}/capture`, null, { signal });
    return response.data;
  }

  static async removePaymentMethod(paymentMethodId: number, signal?: AbortSignal): Promise<any> {
    const response = await authorizedClient.delete(`/api/v1/account/payment_method/${paymentMethodId}`, { signal });
    return response.data;
  }

  static async managePaymentMethod(paymentMethodId: number, signal?: AbortSignal): Promise<{ url: string }> {
    const response = await authorizedClient.get(`/api/v1/account/payment_method/${paymentMethodId}/management_link`, { signal });
    return response.data;
  }
}