import { IRecordEvent } from "../components/Report/FigmaReport/types";
import { IDeviceInfo } from "../components/Report/utils/getDeviceInfo";
import { ICreateAnswerResponse } from "../DTO/Answers";
import { apiClient, authorizedClient } from "../http/Client";
import { ITestAnswer } from "../models/Report/ITestAnswer";


export interface ICreateAnswerRequest {
  testId: string;
  meta: {
    source: string;
    userAgent: string;
    deviceInfo: IDeviceInfo;
  };
  urlParams: Record<string, string>;
  respondentParams: {
    respId: string | null;
    panelId: string | null;
    studyId: string | null;
  };
}

export class SendAnswerRequest {
  constructor(
    public testId: string,
    public answerId: string,
    public blockId: string,
    public data: any,
    public abortSignal?: AbortSignal
  ) {
    this.data = {
      data: data,
      blockId: blockId
    }
  }
}

export interface IAnswerClicksResponse {
  events: IRecordEvent[][];
  eventsTS: number;
  eventsStopTs: number;
  videoTS: number;
  videoStopTs: number;
}

export const basePath = '/api/v1/tests';

export class AnswersAPI {
  static async deleteAnswer(testId: string, answerId: string) {
    const response = await authorizedClient.post(`/api/tests/${testId}/answers/${answerId}/delete`);
    return await response.data;
  }

  static async getAnswersBatch(testId: string, sharingToken: string, lastCreatedAt?: string | number | undefined, abortSignal?: AbortSignal): Promise<{
    answers: ITestAnswer[];
    hasNextPage: boolean;
  }> {
    let apiUrl = `${basePath}/${testId}/answers?sharingToken=${sharingToken}`;
    if (lastCreatedAt) {
      apiUrl += `&lastCreatedAt=${new Date(lastCreatedAt).toISOString()}`;
    }

    const response = await apiClient.get(apiUrl, { signal: abortSignal });
    return response.data;
  }

  static async getAnswerClicks(recordingId: string, sharingToken: string | undefined, abortSignal?: AbortSignal): Promise<IAnswerClicksResponse> {
    const response = await apiClient.get(`/api/v1/answers/${recordingId}/clicks?sharingToken=${sharingToken}`, { signal: abortSignal });
    return response.data;
  }

  static async getAnswerVideoType(videoUrl: string, abortSignal?: AbortSignal) {
    const response = await apiClient.head(videoUrl, { signal: abortSignal });
    return response.headers['content-type'];
  }

  static async approveAnswer({testId, answerId, isAnswersLimitExceeded, studyId}: {testId: string, answerId: string, isAnswersLimitExceeded: boolean, studyId: string | null}) {
    const response = await apiClient.post(`/api/tests/${testId}/answers/${answerId}/approve`, { isAnswersLimitExceeded, studyId });
    return response.data;
  }

  static async createAnswer(request: ICreateAnswerRequest): Promise<ICreateAnswerResponse> {
    const response = await apiClient.post(`/api/tests/${request.testId}/answers`, { ...request.respondentParams, meta: request.meta, urlParams: request.urlParams });
    return response.data;
  }

  static async sendAnswer(request: SendAnswerRequest): Promise<ICreateAnswerResponse> {
    const response = await apiClient.post(`/api/tests/${request.testId}/answers/${request.answerId}`, request.data, { signal: request.abortSignal });
    return response.data;
  }
}