import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { useAuth, useSendNotification } from '../../../hooks';
import { getUserData } from '../../../actions';
import { useLocalStorage } from '../../Common/Hooks/useLocalStorage';
import AppContext from '../../../AppContext';
import { IUser } from '../../../models/User';
import { IAccount, ITariffPlan } from '../../../models/Account/IAccount';
import { IProject } from '../../../models/Project/IProject';
import { AccountAPI, ICreditsOrderRequest, IOrderResponse, ISubscriptionOrderRequest } from '../../../actions/AccountAPI';

export interface ICurrentUserData {
  user: IUser;
  account: IAccount;
  currentProject: IProject | null;
  credits: any;
  subscription: {
    plan: string;
    startedAt: string;
    expiresAt: string;
    usage: {
      tests?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
      responses?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
      users?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
    },
  },
  usage: {
    tests: number;
    responses: number;
    users: number;
    exceededAnswersLimit: boolean;
    exceededTestsLimit: boolean;
  };
}

export function useAccount() {
  const setNotification = useSendNotification();
  const user = useAuth();
  const queryClient = useQueryClient()
  const localStorage = useLocalStorage();
  const context = useContext(AppContext);

  const { t } = useTranslation();

  const currentUser = useQuery<ICurrentUserData>({
    queryKey: ['user'],
    queryFn: () => getUserData(user as User).then(d => {
      Object.assign(d.payload.usage, getUsageWarnings(d.payload.account.currentSubscription.tariff, d.payload.usage));
      context.dispatch(d);
      return d.payload;
    }).catch((e) => {
      if (e.cause?.status === 504) {
        setNotification("error", t("Oops, something went wrong. Maybe you`re offline?"));
      } else {
        setNotification("error", t("Oops, something went wrong."));
      }
      console.error(e);
    }),
    enabled: !!user,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
  });

  const cancelSubscription = useMutation({
    mutationFn: () => AccountAPI.changeSubscription({ isRecurring: false }),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['user'] }) },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while cancelling subscription. Please try again later.' as any));
    },
  });

  const resumeSubscription = useMutation({
    mutationFn: () => AccountAPI.changeSubscription({ isRecurring: true }),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['user'] }) },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while resuming subscription. Please try again later.' as any));
    },
  });

  const upgradeSubscription = useMutation<{ id: number }, Error, ISubscriptionOrderRequest>({
    mutationFn: async (request) => {
      try {
        localStorage.setItem('last_order_request', request);
        return AccountAPI.upgradeSubscription(request);
      } catch (error) {
        console.error(error);
        throw new Error('Failed to upgrade subscription', { cause: error });
      }
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while upgrading your subscription. Please try again later.' as any));
    },
  });

  const buyCredits = useMutation<{ id: number }, Error, ICreditsOrderRequest>({
    mutationFn: (request) => {
      try {
        localStorage.setItem('last_order_request', request);
        return AccountAPI.buyCredits(request);
      } catch (error) {
        console.error(error);
        throw new Error('Failed to buy credits', { cause: error });
      }
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while purchasing credits. Please try again later.' as any));
    },
  });

  const addPaymentMethod = useMutation<{ id: number }, Error>({
    mutationFn: () => {
      try {
        const request = { productCode: 'add_payment_method', paymentMethodCode: 'tinkoff_rf' };
        localStorage.setItem('last_order_request', request);
        console.log(request);
        return AccountAPI.addPaymentMethod(request);
      } catch (error) {
        console.error(error);
        throw new Error('Failed to add payment method', { cause: error });
      }
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while adding payment method. Please try again later.' as any));
    }
  });

  const capturePayment = useMutation<IOrderResponse, Error, number>({
    mutationFn: (paymentId: number) => AccountAPI.capturePayment(paymentId),
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while processing the payment. Please, try again later.' as any));
    },
    onSuccess: (capture) => {
      if (capture.result === 'error') {
        setNotification('error', t('An error occurred while processing the payment. Please, try again later.' as any));
        return;
      }

      if (capture.status === 'success') {
        queryClient.invalidateQueries({ queryKey: ['user'] });
      }
      if (capture.status === 'confirmation_required') {
        window.open(capture.payment_url, '_blank');
      }
    }
  });

  const removePaymentMethod = useMutation({
    mutationFn: (paymentMethodId: number) => AccountAPI.removePaymentMethod(paymentMethodId),
    onSuccess: (response) => {
      if (response.result === 'error') {
        throw response;
      }
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setNotification('warning', t('Payment method successfully deleted.' as any));
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while deleting payment method. Please try again later.' as any));
    },
  });

  const managePaymentMethod = useMutation({
    mutationFn: (paymentMethodId: number) => AccountAPI.managePaymentMethod(paymentMethodId),
  });


  return {
    cancelSubscription,
    resumeSubscription,
    upgradeSubscription,
    buyCredits,
    capturePayment,
    currentUser,
    addPaymentMethod,
    removePaymentMethod,
    managePaymentMethod
  }
}

export function getUsageWarnings(tariff: ITariffPlan, usage: { tests: number; responses: number; users: number; }) {
  return {
    exceededAnswersLimit: (tariff.responsesLimit > 0)
      ? usage.responses >= tariff.responsesLimit
      : false,
    exceededTestsLimit: (tariff.testsLimit > 0)
      ? usage.tests >= tariff.testsLimit
      : false,
  };
}