import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { useConfig } from './ConfigContext';


const FirebaseContext = createContext<FirebaseContextType>({
	auth: null,
	db: null,
	storage: null,
});

export type FirebaseContextType = {
	auth: Auth | null;
	db: Firestore | null;
	storage: FirebaseStorage | null;
};

export function FirebaseProvider({ children }: { children: React.ReactNode }) {
	const [firebase, setFirebase] = useState<FirebaseContextType>({
		auth: null,
		db: null,
		storage: null,
	});

	const { config } = useConfig()

	useEffect(() => {
		if (config) {
			initializeApp(config.firebase_config);
			const auth = getAuth();
			const db = getFirestore();
			const storage = getStorage();
			setFirebase({ auth, db, storage });
		}
	}, [config]);

	return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
}

export function useFirebase() {
	const context = useContext(FirebaseContext);
	if (!context) {
		throw new Error('useFirebase must be used within a FirebaseProvider');
	}
	return context;
}