import { ITester } from "../components/Admin/Testers";
import { apiClient, authorizedClient } from "../http/Client";
import i18n from "../i18n/config";
import { IPGHiringOrder } from "../models/Hiring/IPGHiringOrder";
import { ITest } from "../models/Test";

export class CreateTestRequest {
  constructor(
    public name: string = i18n.t('New test'),
    public sourceTestId: string | null = null,
    public splitGroupId: number | null = null,
    public folderId: string | null = null
  ) {}
}

export interface IUpdateTestRequest {
  testId: string;
  data: Partial<ITest> | Record<string, any>;
}


export class TestsAPI {
  static baseURL = '/api/v1/tests';

  public static async getHiringOrderByTestId(testId: string): Promise<IPGHiringOrder> {
    const response = await authorizedClient.get(`${this.baseURL}/${testId}/hiring_order`);
    return response.data;
  }

  public static async createTest(params: CreateTestRequest, signal?: AbortSignal): Promise<ITest> {
    const response = await authorizedClient.post(`${this.baseURL}`, params, { signal });
    return response.data;
  }

  public static async getTest(testId: string, signal?: AbortSignal): Promise<ITest> {
    const response = await authorizedClient.get(`${this.baseURL}/${testId}`, { signal });
    return response.data;
  }

  public static async getPublicTest(testId: string, sharingToken?: string, signal?: AbortSignal): Promise<ITest> {
    const response = await apiClient.get(`${this.baseURL}/${testId}?sharingToken=${sharingToken}`, { signal });
    return response.data;
  }

  public static async listTests(folderId: string, signal?: AbortSignal): Promise<ITester[]> {
    const response = await authorizedClient.post("/api/user/getTests", { folderId }, { signal });
    return response.data;
  }

  public static async updateTest(data: IUpdateTestRequest, signal?: AbortSignal): Promise<ITest> {
    const response = await authorizedClient.post(`/api/user/updateTest`, data, { signal });
    return response.data;
  }

  public static async getTesters(testId: string, sharingToken: string, signal?: AbortSignal): Promise<ITester[]> {
    const response = await authorizedClient.get(`${this.baseURL}/${testId}/testers?sharingToken=${sharingToken}`, { signal });
    return response.data;
  }

  public static async banTester(testId: string, testerId: string, signal?: AbortSignal): Promise<ITester[]> {
    const response = await authorizedClient.post(`${this.baseURL}/${testId}/testers/${testerId}/block`, { signal });
    return response.data;
  }

  public static async approveTester(testId: string, testerId: string, signal?: AbortSignal): Promise<ITester[]> {
    const response = await authorizedClient.post(`${this.baseURL}/${testId}/testers/${testerId}/approve`, { signal });
    return response.data;
  }

  public static async getTestWithSharingToken(testId: string, sharingToken: string, signal?: AbortSignal): Promise<ITest> {
    const response = await apiClient.get(`${this.baseURL}/${testId}?sharingToken=${sharingToken}`, { signal });
    return response.data;
  }

  public static async getTestResponsesWithSharingToken(testId: string, sharingToken: string, lastCreatedAt: string, signal?: AbortSignal): Promise<any[]> {
    const response = await apiClient.get(`${this.baseURL}/${testId}/answers?sharingToken=${sharingToken}&lastCreatedAt=${lastCreatedAt || ""}`, { signal });
    return response.data;
  }
}
