import { useMutation } from '@tanstack/react-query';
import { signInWithCustomToken } from 'firebase/auth';
import { AxiosError } from 'axios';

import { SignWithEmailDTO } from './LoginWithEmail';
import { useFirebase } from '../../../../config/FirebaseContext';
import { AuthAPI } from '../../../../actions/AuthAPI';

export enum LoginErrorCode {
  TooManyRequests = 'too_many_requests',
  NetworkError = 'network',
  InvalidAuthCode = 'invalid_auth_code',
}

export function useLoginByCode(options: { onSendCodeError?: (errorCode: LoginErrorCode) => void; onVerifyCodeError?: (errorCode: LoginErrorCode) => void; }) {
  const { auth } = useFirebase();

  const sendCode = useMutation<boolean, Error, SignWithEmailDTO>({
    mutationFn: async (data) => {
      try {
        await AuthAPI.login(data);
        return true;
      } catch (error: any) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            throw new Error(error.response?.data.error_code);
          }

          if (error.response?.status === 429) {
            throw new Error(LoginErrorCode.TooManyRequests);
          }
        }

        throw new Error('http_error', { cause: error });
      }
    },
    onError: (error: Error) => {
      console.error(error);
      options.onSendCodeError?.(error.message as any);
    },
  });

  const verifyCode = useMutation<{ token: string }, Error, { email: string; code: string; }>({
    mutationFn: async (data) => {
      try {
        const response = await AuthAPI.verify(data);

        if (!auth) {
          throw new Error('Auth is not initialized');
        }

        await signInWithCustomToken(auth, response.data.token)
        return response.data;

      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            throw new Error(error.response?.data.error_code);
          }

          if (error.response?.status === 429) {
            throw new Error(LoginErrorCode.TooManyRequests);
          }
        }

        throw new Error('http_error', { cause: error });
      }
    },
    onError: (error: any) => {
      console.error(error);
      options.onVerifyCodeError?.(error.message as any);
    },
  });


  return {
    sendCode,
    verifyCode,
  };
}
