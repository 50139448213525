import React from 'react';
import { useLocation } from 'react-router';

const gtmLogIdentifier = ['%c✅ GTM Push. Event:', 'color: #3dadfd; font-size: 14px'];

declare global {
  interface Window {
    dataLayer: any;
  }
}

function log(event: any) {
  try {
    console.log('\n');
    console.group.apply(console, gtmLogIdentifier);
    console.log(event);
    console.groupEnd();
    console.log('\n');
  } catch (e) {
    console.log.apply(console, [...gtmLogIdentifier, event]);
  }

  // We don't need to save events to the database
  // // save event
  // if (ENABLE_LOGGING) {
  //   db.collection('logs').add({
  //     ...event, createdAt: Timestamp.now(),
  //   });
  // }
}

function eventIsLoggable(event = {}) {
  return (
    window.dataLayer &&
    Object.keys(event).length > 0
  );
}

export const usePageViews = () => {
  let location = useLocation();
  React.useEffect(() => {
    pushToGTM({ event: 'pageview', eventId: location.pathname })
  }, [location]);
}

export function pushToGTM(event: any) {
  if (eventIsLoggable(event)) {
    window.dataLayer.push(event);
    log(event);
  }
}
