import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconBack } from '../../icons';
import Logo from '../Logo';
import { useConfig } from '../../config/ConfigContext';

type SignInButtonParams = {
  disabled?: boolean;
};

export const getSignInButtonClassName = (params: SignInButtonParams = {}) => {
  const signInButtonClassName =
    "my-2 py-2 px-5 rounded-full object-center mx-auto text-xl font-medium flex items-center transition-all duration-75 ease-in";
  return params.disabled
    ? signInButtonClassName + " bg-gray-100 text-gray-600 cursor-not-allowed"
    : signInButtonClassName + " bg-white hover:bg-gray-100 text-gray-800 cursor-pointer";
};

type SignInLayoutProps = {
  title: string;
  backUrl?: string;
  children?: any;
};

function SignInLayout(props: SignInLayoutProps) {
  const { t } = useTranslation();
  const { config } = useConfig();
  const licenseLink = config?.urls.license_agreement;

  return (
    <div className="container mx-auto h-full text-center flex flex-col">
      <div className="mx-auto mt-12">
        <Logo clickable={false} />
      </div>
      <div className="flex justify-center items-center flex-1">
        <div>
          <div className="relative text-3xl font-bold font-display mb-8" style={{ padding: props.backUrl ? "0 60px" : 0 }}>
            {props.backUrl && (
              <Link className="block absolute left-0" to={props.backUrl} style={{ top: "50%", marginTop: "-16px" }}>
                <IconBack
                  width={32}
                  height={32}
                  className="fill-current text-gray-800 cursor-pointer hover:text-gray-700 transition-all duration-75 ease-in" />
              </Link>
            )}
            {props.title}
          </div>

          {props.children}

          <p className="mt-8 text-base text-gray-600">© 2024 Pathway</p>
          <p className="text-base">
            <a href={`mailto:${config?.contact_email}`} target="_blank" rel="noopener noreferrer">
              {t("Contact us")}
            </a>
            &nbsp;•&nbsp;
            <a className="" href={licenseLink} target="_blank">
              {t("License agreement")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignInLayout;
