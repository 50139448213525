import React from "react";
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './i18n/config';
import App from "./App";
import TagManager from './components/TagManager';
import * as serviceWorker from "./serviceWorker";

import { ConfigProvider } from './config/ConfigContext';
import { SentryLoggerProvider } from './config/SentryContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nProvider } from './i18n/I18nProvider';
import { FirebaseProvider } from './config/FirebaseContext';

window.addEventListener('vite:preloadError', () => { window.location.reload(); });

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient();

const app = (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <SentryLoggerProvider>
          <FirebaseProvider>
            <I18nProvider>
              <TagManager />
              <App />
            </I18nProvider>
          </FirebaseProvider>
        </SentryLoggerProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </HelmetProvider>
)

// console.log("isDev", import.meta.env.DEV);

root.render(
  import.meta.env.NODE_ENV === "development" ? (
    <React.StrictMode>
      {app}
    </React.StrictMode>
  ) : (
    app
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
