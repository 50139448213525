
import { authorizedClient } from '../http/Client';

export interface IInvitation {
  invite: {
    code: string;
    createdAt: string;
    ownerId: string;
    projectId: string;
  }
}

export interface IGetInviteByCodeResponse {
	invite: {
		teamName: string;
	}
}

export const basePath = '/api/invites';

export class InvitesAPI {

	public static async getInvite(projectId: string): Promise<IInvitation> {
		const response = await authorizedClient.post<any>(`${basePath}`, { projectId });
		return response.data;
	}

	public static async resetInvite(projectId: string): Promise<IInvitation> {
		const response = await authorizedClient.post<any>(`${basePath}/reset`, { projectId });
		return response.data;
	}

	public static async getInviteByCode(inviteCode: string): Promise<IGetInviteByCodeResponse> {
		const response = await authorizedClient.get(`${basePath}/${inviteCode}`);
		return response.data;
	}

	public static async acceptInvite(inviteCode: string): Promise<{ result: string }> {
		const response = await authorizedClient.post(`${basePath}/${inviteCode}/accept`);
		return response.data;
	}

	public static async createInvite(projectId: string): Promise<string> {
		const response = await authorizedClient.post<IInvitation>(`${basePath}`, { projectId });
		return `${window.location.protocol}//${window.location.host}/invite/${response.data.invite.code}`;
	}
}
