import { IActionResult } from "../AppContext";
import { pushToGTM } from "../utils/gtm";
import { CreateTestRequest, TestsAPI } from "./TestsAPI";
import { ITest } from "../models/Test";
import { isAbortError } from "./utils";


export class TestsActions {
	public static async banTester(testId: string, testerId: string) {
		try {
			await TestsAPI.banTester(testId, testerId);

			return {
				type: "BAN_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};

		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async approveTester(testId: string, testerId: string) {
		try {
			await TestsAPI.approveTester(testId, testerId);

			return {
				type: "APPROVE_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async getTesters(testId: string, sharingToken: string) {
		try {
			const testResponse = await TestsAPI.getTesters(testId, sharingToken);

			return {
				type: "GET_TESTERS",
				payload: {
					data: {
						[testId]: testResponse,
					},
				},
			};
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async getTest(testId: string) {
		try {
			const testResponse = await TestsAPI.getTest(testId);

			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: testResponse,
				},
			};
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async getTestWithSharingToken(testId: string, sharingToken: string) {
		try {
			const testResponse = await TestsAPI.getTestWithSharingToken(testId, sharingToken);

			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: testResponse,
				},
			};
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async getTestResponsesWithSharingToken(testId: string, sharingToken: string, lastCreatedAt: string) {
		try {
			const testResponse = await TestsAPI.getTestResponsesWithSharingToken(testId, sharingToken, lastCreatedAt);
			return testResponse;
		} catch (error) {
			throw new Error("http_error", { cause: error });
		}
	}

	public static async createTest(requestParams: CreateTestRequest, signal?: AbortSignal): Promise<{ action: IActionResult<Record<string, ITest>>, test: ITest }> {
		try {
			const test = await TestsAPI.createTest(requestParams, signal);

			pushToGTM({
				event: "Test created",
				userId: test.userId,
				projectId: test.projectId,
			});

			return {
				action: {
					type: "CREATE_TEST",
					payload: {
						data: { [test.id!]: test as ITest },
					},
				},
				test,
			};
		} catch (error) {
			console.error('Failed to create test:', error);
			throw new Error("http_error", { cause: error });
		}
	}

	public static async listTests(folderId: string, signal?: AbortSignal) {
		try {
			const testsResponse = await TestsAPI.listTests(folderId, signal);

			return {
				type: "GET_TESTS",
				payload: {
					tests: testsResponse,
				},
			};
		} catch (error) {
			if (error instanceof Error && isAbortError(error)) {
				throw new Error("canceled_error", { cause: error });
			}

			throw new Error("http_error", { cause: error });
		}
	}
}
