// DEPRECATED: This file will be removed in future releases. Please avoid using functions from this file.

import { collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import _ from "lodash";

import i18n from "./i18n/config";

import { IUserDataRequest, UserAPI } from "./actions/UserAPI";
import { TestsAPI } from "./actions/TestsAPI";
import { AnswersAPI } from "./actions/AnswersAPI";
import { TeamsAPI } from "./actions/TeamsAPI";
import { NotificationType } from "./hooks";

export function updateCredits(value: number) {
  return {
    type: "UPDATE_CREDITS",
    payload: {
      value: value,
    },
  };
};

export async function getUserData(user: User) {
  const authData: IUserDataRequest = {
    userId: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    detectedLanguage: i18n.language,
  };

  try {
    const userDataResponse = await UserAPI.getUserData(authData);

    return {
      type: "GET_USER_DATA",
      payload: userDataResponse,
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function updateTest(data: any, testId: string) {
  try {
    await TestsAPI.updateTest({ testId, data });

    return {
      type: "UPDATE_TEST",
      payload: {
        data: data,
        testId: testId,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function updateTestSettings(key: string, value: any, testId: string) {
  try {
    await TestsAPI.updateTest({ testId, data: { [`settings.${key}`]: value } });

    return {
      type: "UPDATE_TEST_SETTINGS",
      payload: {
        key: key,
        value: value,
        testId: testId,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function deleteAnswer(answerId: string, testId: string) {
  try {
    await AnswersAPI.deleteAnswer(testId, answerId);
    return {
      type: "DELETE_ANSWER",
      payload: {
        testId: testId,
        answerId: answerId,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function setUserData(userId: string, data: any) {
  try {
    const db = getFirestore();
    const usersCollection = collection(db, "users");
    const userDoc = doc(usersCollection, userId);
    await updateDoc(userDoc, { ...data });

    return {
      type: "SET_USER_DATA",
      payload: {
        userId: userId,
        data: data,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export function setNotification(type: NotificationType, text: string | null) {
  return {
    type: "SET_NOTIFICATION",
    payload: {
      type: type,
      text: text,
    },
  };
};

export function setUi(key: string, value: any) {
  return {
    type: "SET_UI",
    payload: {
      key: key,
      value: value,
    },
  };
};

export async function getTeam(projectId: string) {
  try {
    const response = await TeamsAPI.getTeam(projectId);

    return {
      type: "GET_TEAM",
      payload: {
        team: response,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function updateRole(projectId: string, memberId: string, role: string) {
  try {
    await TeamsAPI.updateTeamMemberRole(projectId, memberId, role);
    return {
      type: "UPDATE_ROLE",
      payload: {
        memberId: memberId,
        role: role,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function removeMember(projectId: string, memberId: string) {
  try {
    await TeamsAPI.removeTeamMember(projectId, memberId);
    return {
      type: "REMOVE_MEMBER",
      payload: {
        memberId: memberId,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};

export async function updateTeam(projectId: string, field: string, value: string) {
  try {
    await TeamsAPI.updateTeamSettings({ projectId, field, value });

    return {
      type: "UPDATE_TEAM",
      payload: {
        field: field,
        value: value,
      },
    };

  } catch (error) {
    console.error(error);
    throw new Error("http_error", { cause: error });
  }
};
