import { authorizedClient } from "../http/Client";

export class AdminAPI {
  static baseURL = '/api/admin';

  public static async getCustomToken(userId: string): Promise<{ customToken: string }> {
    const response = await authorizedClient.get(`${this.baseURL}/customToken?userId=${userId}`);
    return response.data;
  }

  public static async getTests(paginationCursor: string | null): Promise<{ tests: any[], paginationCursor: any }> {
    const response = await authorizedClient.post(`${this.baseURL}/getTests`, { paginationCursor });
    return response.data;
  }
}
