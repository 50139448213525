import { AxiosResponse } from "axios";
import { ExportFormat } from "../components/Report/hooks/useReportExport";
import { apiClient, authorizedClient } from "../http/Client";

export type ReportConfigData = {
  report: any,
  blocks: Record<string, any>
} | null;

export type ReportCategorySuggestionsRequest = {
  groupSuggestions: {
    name: string,
    ids: string[],
    suggestionId: string
  }[],
  categories: {
    name: string,
    id: string
  }[]
}

export type ReportAiSummaryRequest = {
  question: string,
  responses: string[]
}

export class ReportAPI {
  static baseURL = '/api/v1/report';

  public static async getReportConfig(testId: string, sharingToken?: string): Promise<ReportConfigData> {
   let response;

    if (sharingToken) {
      response = await apiClient.get(`${this.baseURL}/${testId}/config?sharingToken=${sharingToken}`);
    } else {
      response = await authorizedClient.get(`${this.baseURL}/${testId}/config`);
    }

    return response.data;
  }

  public static async updateReportBlockConfig(testId: string, blockId?: string, config?: any): Promise<any> {
    const response = await authorizedClient.put(`${this.baseURL}/${testId}/${blockId}/config`, config);
    return response.data;
  }

  public static async exportReport(testId: string, format: ExportFormat, sharingToken?: string, signal?: AbortSignal): Promise<AxiosResponse<Blob>> {
    const response = await apiClient.get(`${this.baseURL}/${testId}/export?sharingToken=${sharingToken}&format=${format}`, { responseType: 'blob', signal });
    return response;
  }

  public static async setReportCategorySuggestions(testId: string, blockId: string, data: ReportCategorySuggestionsRequest, signal?: AbortSignal): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/${testId}/${blockId}/ai/category_suggestions`, data, { signal });
    return response;
  }

  public static async getReportAiSummary(testId: string, blockId: string, data: ReportAiSummaryRequest, signal?: AbortSignal): Promise<any> {
    const response = await authorizedClient.post(`${this.baseURL}/${testId}/${blockId}/ai/summary`, data, { signal });
    return response.data;
  }

  public static async downloadReport(projectId: string, reportType: string, signal?: AbortSignal): Promise<AxiosResponse<Blob>> {
    const response = await authorizedClient.post(`/api/v1/projects/${projectId}/${reportType}`, null, { responseType: 'blob', signal });
    return response;
  }

}