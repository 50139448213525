import { IPrototypeData } from "../components/Figma/Models";
import { authorizedClient } from "../http/Client";
import { ITemplateWithTestContent, Template } from "../models/Template/Template";


export class FigmaAPI {
  static baseURL = '/api/v1/figma';

  public static async setFigmaAuth(code: string | null, state: string | null): Promise<ITemplateWithTestContent[] | null> {
    const response = await authorizedClient.post(`${this.baseURL}/oauth/getAccessToken`, { code, state });
    return response.data;
  }

  public static async getFigmaAuthRedirectUrl(): Promise<{ redirectUrl: string }> {
    const response = await authorizedClient.get(`${this.baseURL}/oauth/getAuthRedirectUrl`);
    return response.data;
  }

  public static async extractPrototypeData(fileId: string, startingNodeId: string | null, userId: string, body: { fileId: string | null, startingNodeId: string | null }): Promise<IPrototypeData> {
    const response = await authorizedClient.post(`${this.baseURL}/extractPrototypeData?fileId=${fileId}&startingNodeId=${startingNodeId}&userId=${userId}`, body);
    return response.data;
  }

  public static async getAuth(): Promise<any> {
    const response = await authorizedClient.get('/api/v1/figma/auth/start');
    return response.data;
  }
}
