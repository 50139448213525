import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";

import { SENTRY_RELEASE, SENTRY_TRACES_SAMPLE_RATE } from '../config';
import { useConfig } from './ConfigContext';
import { ILoggerService, SentryLogger, ConsoleLogger } from '../services/LoggerService';

const LoggerContext = createContext<ILoggerService | null>(null);

export function SentryLoggerProvider(props: PropsWithChildren) {
	const { config } = useConfig();
	const [logger, setLogger] = useState<ILoggerService | null>(new ConsoleLogger());
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		if (isInitialized) return;
		if (SENTRY_RELEASE && config?.sentry?.dsn) {
			Sentry.init({
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.replayIntegration(),
				],
				tracePropagationTargets: ["localhost", /^https:\/\/app\.pthwy\.design/],
				// Performance Monitoring
				tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE ? Number(SENTRY_TRACES_SAMPLE_RATE) : 1, // Capture 100% of the transactions
				// Session Replay
				replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
				release: SENTRY_RELEASE,
				...config.sentry,
			});
			setLogger(new SentryLogger());
		} else {
			console.warn('Sentry is not initialized');
		}
		if (config) setIsInitialized(true);
	}, [config, isInitialized]);

	return (
		<LoggerContext.Provider value={logger}>
			{props.children}
		</LoggerContext.Provider>
	);
}