import React from "react";
import { IFolder, ISplitGroup, ITest } from "./models/Test";
import { IUser } from "./models/User";
import { IAccount } from './models/Account/IAccount';

export const DefaultAppState: IAppState = {
  user: {} as IUser,
  account: {} as IAccount,
  splitGroups: [],
  tests: {},
  testers: {},
  folders: {},
  heatmaps: {},
  credits: {},
  team: {},
  app: {
    notification: {
      type: null,
      text: null,
    },
    ui: {
      isUpgradeModalOpen: false,
    },
  },
  customTargets: []
};

const DefaultAppContext: IAppContext = {
  state: DefaultAppState,
  dispatch: (result: any) => result,
};

export const AppContext = React.createContext<IAppContext>(DefaultAppContext);

export default AppContext;

export interface IAppContext {
  state: IAppState;
  dispatch<T>(result: IActionResult<T>): React.Dispatch<IActionResult<T>> | void;
}

export interface IAppState {
  user: IUser;
  account: IAccount,
  tests: Record<string, ITest>;
  testers: Record<string, any>;
  splitGroups: ISplitGroup[];
  folders: Record<string, IFolder>;
  customTargets: any[];
  heatmaps: any;
  credits: any;
  team: any;
  app: {
    notification: {
      type: null | string;
      text: null | string;
    };
    ui: {
      isUpgradeModalOpen: boolean;
    };
  };
}

export interface IActionResult<T> {
  type: string;
  payload: {
    data?: T;
    tests?: T;
    testId?: string;
    answerId?: string;
    team?: any;
    value?: any;
    type?: any;
    text?: any;
  };
}
