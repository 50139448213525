import { AppConfigDTO } from '../Common/schema/AppConfig/AppConfigDTO';
import { apiClient } from '../http/Client';

export class ConfigAPI {

	public static async getConfig() {
		const response = await apiClient.get<AppConfigDTO>('/api/v1/config');
		return response.data;
	}

}
