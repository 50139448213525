import React, { useEffect, useState } from 'react';
import { initializeI18n } from "./config";
import { useConfig } from '../config/ConfigContext';

export function I18nProvider({ children }: { children: React.ReactNode }) {
	const { config } = useConfig();
	const [isInitialized, setIsInitialized] = useState(false);
	
	useEffect(() => {
		if (!config) return;
		if (isInitialized) return;

		initializeI18n(config);
		setIsInitialized(true);
	}, [config, isInitialized]);

	return <>{children}</>;
}
