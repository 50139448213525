import { apiClient } from '../http/Client';

export class StorageAPI {
	static basePath = '/api/v1/storage';

	public static async uploadFile(name: string, data: string | Blob, mimeType: string, fileName: string, options?: { path?: string, prefix?: 'none' | 'timestamp' }) {
		//  convert string to blob
		const blob = new Blob([data], { type: mimeType });

		//  create formdata object
		const formData = new FormData();
		formData.append(name, blob, fileName);

		//  send request
		const response = await apiClient.post(`${this.basePath}/${(options?.path || '')}?prefix=${options?.prefix}`, formData);
		if (response.status !== 201) {
			throw new Error("Failed to upload images");
		}
		return response.data;
	}

	public static async uploadFiles(files: { name: string, data: string | Blob, mimeType: string, fileName: string }[], options?: { path?: string, prefix?: 'none' | 'timestamp' }) {
		const formData = new FormData();
		files.forEach(file => {
			const blob = new Blob([file.data], { type: file.mimeType });
			formData.append(file.name, blob, file.fileName);
		});

		const response = await apiClient.post(`${this.basePath}/${(options?.path || '')}?prefix=${options?.prefix}`, formData);
		if (response.status !== 201) {
			throw new Error("Failed to upload images");
		}
		return response.data;
	}

	public static async getRecording(options: { blockId: string, answerId: string }) {
		const response = await apiClient.get<any[]>(`${this.basePath}/getRecording?blockId=${options.blockId}&answerId=${options.answerId}`);
		return response.data;
	}
	
}
