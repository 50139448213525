import React, { Suspense, useEffect, useContext, useState, PropsWithChildren } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Loader from "./components/Loader";
import Questionnaire from "./components/UserAccount/Components/Questionnaire/Questionnaire";
import appContext from "./AppContext";

import AuthByToken from "./components/AuthByToken";
import OAuthFigma from "./components/Figma/Editor/Import/OAuthFigma";
import { useAccount } from './components/UserAccount/Hooks/useAccount';
import { pushToGTM } from "./utils/gtm";
import { usePageViews } from "./utils/gtm";
import i18n from "./i18n/config";
import { ENVIRONMENT } from './config';
import { collection, Firestore, onSnapshot, query, where } from 'firebase/firestore';
import { useFirebase } from './config/FirebaseContext';

const Home = React.lazy(() => import("./components/UserAccount/Pages/Home/Home"));
const Settings = React.lazy(() => import("./components/UserAccount/Pages/Settings/Settings"));
const ProjectReports = React.lazy(() => import("./components/UserAccount/Pages/Settings/ProjectReports"));
const Test = React.lazy(() => import("./components/Respondent/Pages/Test/Test"));
const SplitGroup = React.lazy(() => import("./components/UserAccount/Pages/SplitGroup/SplitGroup"));
const TestPage = React.lazy(() => import("./components/TestPage"));
const PageNotFound = React.lazy(() => import("./components/Common/PageNotFound"));

export default function UserApp() {
  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  );
}

function Routes() {
  usePageViews();

  return (
    <Switch>
      <Route exact path="/authWithFigmaToken">
        <OAuthFigma />
      </Route>
      <Route exact path="/authByToken">
        <AuthByToken />
      </Route>
      {/* <Route exact path="/admin">
        <Admin />
      </Route> */}
      <Route exact path="/">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Home />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/settings">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Settings />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/settings/reports">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <ProjectReports />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/f/*">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Home />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/sg/:splitGroupId">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <SplitGroup />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/tests/:testId">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <TestPage />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/tests/:testId/preview">
        <Suspense fallback={<Loader />}>
          <Test isPreview={true} />
        </Suspense>
      </Route>
      <Route exact path="/:testId">
        <Suspense fallback={<Loader />}>
          <Test isPreview={false} />
        </Suspense>
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

function WithUserData(props: PropsWithChildren) {
  const { dispatch, state } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(true);
  const account = useAccount();
  const { db } = useFirebase();

  useEffect(() => {
    const resetCallbacks = [] as (() => void)[];
    if (!account.currentUser.data) return;
    if (!db) {
      console.warn('Firestore is not initialized');
      return;
    }
    const userData = account.currentUser.data;
    const projectId = userData.currentProject?.id as any;
    const domainName = import.meta.env.MODE === "development" ? undefined : window.location.hostname;

    // set language
    if (userData.user.language && userData.user.language !== i18n.language) {
      i18n.changeLanguage(userData.user.language);
    }

    initIntercom(userData.user.email, userData.user.userId, userData.user.displayName, projectId, domainName);
    pushAppOpenEvent(userData.user.userId, projectId);

    //set tests listener
    const unsubscribe = subscribeToTestsUpdates(db, projectId, userData.user.userId, dispatch);

    resetCallbacks.push(unsubscribe);
    setIsLoading(false);

    return () => resetCallbacks.forEach((fn) => fn());
  }, [account.currentUser.data, db])

  if (isLoading) {
    return <Loader />;
  }

  if (!state.user.questionnaireCompleted) {
    return <Questionnaire />;
  }

  return <>{props.children}</>;
}

function subscribeToTestsUpdates(db: Firestore, projectId: string, userId: string, dispatch: any) {
  let isInitialDataLoaded = false;
  const testsCollection = collection(db, "tests");
  const currentUserOrPorjectTestsQuery = query(testsCollection, where(projectId ? "projectId" : "userId", "==", projectId ? projectId : userId));

  const unsubscribe = onSnapshot(currentUserOrPorjectTestsQuery, (testsSnapshot) => {
    if (!isInitialDataLoaded) {
      isInitialDataLoaded = true;
    } else {
      testsSnapshot.docChanges().forEach((change) => {

        const {
          hiringCounter = 0, answersCounter = 0,
        } = change.doc.data();

        dispatch({
          type: "UPDATE_TEST",
          payload: {
            testId: change.doc.id,
            data: {
              hiringCounter,
              answersCounter,
            },
          },
        });
      });
    }
  });
  return unsubscribe;
}

function initIntercom(email: string | null, uid: string, name?: string, projectId?: string, domain?: string) {
  const isProduction = ENVIRONMENT.includes('production') || window.location.hostname === 'app.pathway.cc' || window.location.hostname === 'app.pthwy.design';

  if (isProduction) {
    window.Intercom("boot", {
      app_id: "zbknt5pa",
      email: email,
      user_id: uid,
      name: name || undefined,
      projectId: projectId || undefined,
      domain: domain || undefined,
    });
  }
}

function pushAppOpenEvent(userId: string, projectId?: string) {
  pushToGTM({
    event: "App Opened",
    userId: userId,
    projectId: projectId || null,
  });
}